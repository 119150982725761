import * as React from "react";
import Seo from "../components/seo";
import AboutContainer from "../containers/about";
import { translations } from "../translations";
import type { Translation } from "../types/main";

const t: Translation = {
	language: "en",
	translations: translations.en,
};

const IndexPage = () => (
	<>
		<Seo title="About us" lang="en" />
		<AboutContainer t={t} />
	</>
);

export default IndexPage;
