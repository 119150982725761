import { Box, Container, Divider } from "@chakra-ui/react";
import * as React from "react";
import About from "../components/about";
import Contact from "../components/contact";
import Encourage from "../components/encourage";
import Footer from "../components/footer";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import type { Translation } from "../types/main";

const AboutContainer = ({ t }: { t: Translation }) => (
	<Layout>
		<Navbar t={t} />
		<Container maxW="5xl" py={12}>
			<About t={t} />
		</Container>
		<Container maxW="8xl" py={12}>
			<Contact t={t} />
		</Container>
		<Box bgColor="blue.50">
			<Container maxW="5xl" py={12}>
				<Encourage t={t} />
			</Container>
		</Box>
		<Divider />
		<Container maxW="8xl" py={12}>
			<Footer t={t} />
		</Container>
	</Layout>
);

export default AboutContainer;
