import {
	Box,
	Center,
	Heading,
	Link,
	SimpleGrid,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import type { Translation } from "../types/main";

export default function About({ t }: { t: Translation }) {
	const tt = t.translations.about;
	return (
		<Stack as={Box} textAlign={"center"} spacing={4}>
			<Heading as="h2" fontSize="3xl">
				{tt.title}
			</Heading>
			<VStack spacing={2} textAlign="center">
				<Text fontSize="xl" color={"gray.700"}>
					{tt.textOne}
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					{tt.textTwo}
				</Text>
			</VStack>
			<Center>
				<SimpleGrid
					mt="1rem"
					spacing={{ base: "1rem", xl: "5rem" }}
					columns={{ base: 1, sm: 3 }}
				>
					<VStack>
						<StaticImage
							src="../images/about/susanne-tedsjo.jpg"
							width={150}
							formats={["auto", "webp", "avif"]}
							alt="Susanne Tedsjö"
							imgStyle={{ borderRadius: "100%" }}
						/>
						<Text>
							<b>
								<Link
									isExternal
									href="https://www.linkedin.com/in/susanne-tedsj%C3%B6-3bb7657/"
								>
									Susanne Tedsjö
								</Link>
							</b>
							<br />
							{tt.ceo}
							<br />
							susanne@flowby.io
						</Text>
					</VStack>
					<VStack>
						<StaticImage
							src="../images/about/anna-leijon.jpg"
							width={150}
							formats={["auto", "webp", "avif"]}
							alt="Anna Leijon"
							imgStyle={{ borderRadius: "100%" }}
						/>
						<Text>
							<b>
								<Link isExternal href="https://annaleijon.se/">
									Anna Leijon
								</Link>
							</b>
							<br />
							{tt.cpo}
							<br />
							anna@flowby.io
						</Text>
					</VStack>
					<VStack>
						<StaticImage
							src="../images/about/joakim-lustig.png"
							width={150}
							formats={["auto", "webp", "avif"]}
							alt="Joakim Lustig"
							imgStyle={{ borderRadius: "100%" }}
						/>
						<Text>
							<b>
								<Link isExternal href="https://joakimlustig.com/">
									Joakim Lustig
								</Link>
							</b>
							<br />
							{tt.cto}
							<br />
							joakim@flowby.io
						</Text>
					</VStack>
				</SimpleGrid>
			</Center>
		</Stack>
	);
}
